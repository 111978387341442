import { format, parse, startOfYear } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { setHours, startOfMonth } from 'date-fns/index.js'
import { es } from 'date-fns/locale/index.js'
import { WeekDayEnum } from 'types/enums.ts'
import { hoursRegEx } from '~/models/availableDates.ts'

// Validates if string is of format YYYY-MM-DD
export function isValidDate(input: string) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/

  if (!dateRegex.test(input)) {
    return false
  }

  const parts = input.split('-')
  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const day = parseInt(parts[2], 10)

  const date = new Date(year, month - 1, day)

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  )
}

export function getFirstDayOfCurrentMonth(monthDate?: string | Date | null) {
  const date = monthDate ? new Date(monthDate) : new Date()
  const startOfMonthDate = startOfMonth(date)
  const startOfMonthDateWithHour00 = setHours(startOfMonthDate, 0)

  return startOfMonthDateWithHour00 as Date
}

export function getFirstDayOfCurrentYear() {
  return startOfYear(new Date())
}

export function dateToDataTable(dateString?: string | Date | null) {
  if (!dateString) return null
  return format(new Date(dateString), 'dd/MM/yyyy')
}

export function dateToDateTime(dateString?: string | Date | null) {
  if (!dateString) return null
  return format(new Date(dateString), 'dd/MM/yyyy HH:mm')
}

// dd/MM/yyyy => Date
export function dataTableToDate(dateString?: string | null) {
  if (!dateString) return null
  return parse(dateString, 'dd/MM/yyyy', new Date())
}

// dd/MM/yyyy HH:mm => Date
export function fullDateToDate(dateString?: string | null) {
  if (!dateString) return null
  return parse(dateString, 'dd/MM/yyyy HH:mm', new Date())
}

// 31 de agosto 2023
export function dateToFriendly(dateString: string | Date, timeZone?: string) {
  const date = timeZone
    ? utcToZonedTime(dateString, timeZone)
    : new Date(dateString)
  return format(date, 'dd MMMM yyyy', { locale: es }).replace(' ', ' de ')
}

export function dateToFriendlyDay(dateString: string | Date) {
  const date = new Date(dateString)
  return format(date, 'dd', { locale: es })
}

export function dateToFriendlyYear(dateString: string | Date) {
  const date = new Date(dateString)
  return format(date, 'yyyy', { locale: es })
}

export function dateToFriendlyAbrev(dateString: string | Date) {
  const date = new Date(dateString)
  return format(date, 'dd MMM', { locale: es })
}

export function dateToFriendlyMonthAbrev(dateString: string | Date) {
  const date = new Date(dateString)
  return format(date, 'MMM', { locale: es })
}

export function dateToInput(dateString?: string | Date, timeZone?: string) {
  if (!dateString) return undefined
  const date = timeZone
    ? utcToZonedTime(dateString, timeZone)
    : new Date(dateString)
  return format(date, 'yyyy-MM-dd')
}

// 02:05 PM (hora local)
export function timeToFriendly(dateString: string | Date) {
  const date = new Date(dateString)
  return format(date, 'HH:mm', { locale: es }) + ' (hora local)'
}

export function timeAbrev(dateString: string | Date) {
  const date =
    typeof dateString === 'string' ? new Date(dateString) : dateString
  return format(date, 'HH:mm', { locale: es })
}

export function timeAbrevUTC(dateString: string | Date) {
  const date = new Date(dateString)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const hoursString = hours < 10 ? `0${hours}` : hours.toString()
  const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString()

  return `${hoursString}:${minutesString}`
}

export function timeAbrevAMPM(dateString: string | Date) {
  const date = new Date(dateString)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const isPM = hours >= 12
  const displayHours = hours % 12 || 12

  return `${displayHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${isPM ? 'PM' : 'AM'}`
}

export function timeToFriendlyAbrev(dateString: string | Date) {
  const date = new Date(dateString)
  return format(date, 'HH:mm', { locale: es }) + ' hrs'
}

// time format 11:30
export function setTime(date: Date | string, time: string) {
  const [hours, minutes] = time.split(':')
  const newDate = new Date(date)
  newDate.setHours(parseInt(hours))
  newDate.setMinutes(parseInt(minutes))
  return newDate
}

export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter, index) => {
    return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase()
  })
}

// 2 decimals
export const formatPrice = (price: number | undefined | null) => {
  if (price === undefined || price === null) {
    return
  }

  let priceInString = price.toLocaleString('es-ES', {
    maximumFractionDigits: 2,
  })

  // toLocaleString don't put . if it is smaller than 10000, this is to: 1000 => 1.000
  if (price >= 1000 && price < 10000) {
    priceInString =
      priceInString.substring(0, 1) + '.' + priceInString.substring(1)
  }

  if (price > 100000) {
    return priceInString.split(',')[0]
  }

  // if has decimals, but decimal part don't has 2 numbers place a 0, example: 100,2 => 100,20
  const hasDecimals = priceInString.split(',').length > 1
  if (hasDecimals && priceInString.split(',')[1].length < 2) {
    priceInString += 0
  }

  return priceInString
}

export function formatDecimals(number: number, maxDecimalNumbers: number) {
  if (Number.isInteger(number)) {
    return number
  }
  return Number(number.toFixed(maxDecimalNumbers))
}

export const minutesToTimeType = (minutes: number) => {
  if (minutes >= 60 && minutes < 60 * 24 && minutes % 60 === 0) {
    return { time: minutes / 60, type: 'hours' }
  }
  if (minutes >= 60 * 24 && minutes % (60 * 24) === 0) {
    return { time: minutes / (60 * 24), type: 'days' }
  }
  return { time: minutes, type: 'minutes' }
}

export const timeTypeToMinutes = (
  time: number,
  type: 'hours' | 'days' | 'minutes',
) => {
  if (type === 'days') {
    return time * 60 * 24
  }
  if (type === 'hours') {
    return time * 60
  }
  return time
}

// minutes=120 => '2 hours'
export const minutesTimeToFriendly = (timeInMinutes: number) => {
  const { time, type } = minutesToTimeType(timeInMinutes)
  return `${time} ${type}`
}

// 03:30 => 210 (minutes)
export const dateToMinutes = (date: Date) => {
  return new Date(date).getHours() * 60 + new Date(date).getMinutes()
}

// minutes=120 => '02:00'
export function minutesToTime(timeInMinutes: number) {
  const hours = Math.floor(timeInMinutes / 60)
  const minutes = timeInMinutes - hours * 60

  let hoursString = String(hours)
  let minutesString = String(minutes)

  if (hours < 10) {
    hoursString = `0${hours}`
  }

  if (minutes < 10) {
    minutesString = `0${minutes}`
  }

  return `${hoursString}:${minutesString}`
}

// '02:00' => minutes=120
export function timeToMinutes(time: string) {
  if (!hoursRegEx.test(time)) {
    return
  }
  const [hours, minutes] = time.split(':')

  const hoursInMinutes = Number(hours) * 60

  const total = hoursInMinutes + Number(minutes)

  return total
}

// Lunes => 1
export function stringToDayOfWeek(dayOfWeekStr: string) {
  const daysOfWeek = Object.values(WeekDayEnum)

  if (daysOfWeek.find(dayName => dayName === dayOfWeekStr)) {
    return daysOfWeek.indexOf(dayOfWeekStr)
  }

  return
}

export function dayIndexToString(dayIndex: number | undefined) {
  if (!dayIndex && dayIndex !== 0) return undefined
  const daysOfWeek = Object.values(WeekDayEnum)

  if (dayIndex > daysOfWeek.length - 1) {
    return undefined
  }

  return daysOfWeek[dayIndex]
}

export function yearMonthAndDayToDate({
  fullYear,
  month = 1,
  date = 1,
}: {
  fullYear: number | string
  month?: number | string
  date?: number | string
}) {
  const monthToString = month.toString().padStart(2, '0')
  const datehToString = date.toString().padStart(2, '0')

  const newDate = new Date(
    `${fullYear}-${monthToString}-${datehToString} 00:00`,
  )

  if (isNaN(newDate.getTime())) {
    return undefined
  }

  return newDate
}

export function nameToSlug(name: string | undefined | null) {
  if (!name) {
    return name
  }

  const nameWithoutAcents = replaceAcents(name.toLocaleLowerCase())
  const withoutSpecialCharacters = nameWithoutAcents.replace(/[?&/\\|{}]/g, '')
  const nameWithoutMultipleBlanks = removeMultipleBlanks(
    withoutSpecialCharacters,
  )
  return nameWithoutMultipleBlanks.replaceAll(' ', '-')
}

export function replaceAcents(text: string) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

// 'Hello     world,  what  a   wondefull day' => 'Hello world, what a wondefull day'
export function removeMultipleBlanks(text: string) {
  return text.replace(/\s+/g, ' ')
}

// To be used backend when: CREATING DATES IN BACKEND FROM STRING.
// '10:30' in fronted Timezone, if converted into new Date UTC it becomes '10:30' in UTC, then taken in frontend => 10:30 + diff between UTC and Timezone
export function dateUTCFromZonedDate(date: Date, timeZone: string): Date {
  return zonedTimeToUtc(date, timeZone)
}

// To be used in backend when: getting a date or day with timezone data
// '15 of January at 23:00' in UTC may be '16 of January at 00:00' in zoned time. If getting the day it would take it wrong
export function zonedDateFromUTC(date: Date, timeZone: string): Date {
  return utcToZonedTime(date, timeZone)
}

export function capitalize(str: string | null | undefined) {
  if (str == null) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}
